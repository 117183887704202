import React from "react"
import styled from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";

const StyledDiv = styled.div`
  background: gray;

  position: relative; 
  padding-top: 56.25%; /* 16:9 Aspect Ratio */ 
          
  margin-top: 100px;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
  }
`;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <StyledDiv>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </StyledDiv>
)

const AboutUsPage = () => (
  <Layout>
    <SEO title="Oferta" />
    <Hero title="OFERTA" />
    <Video videoSrcURL="https://www.youtube.com/embed/kj4P1XFq8gc" videoTitle="Prezentacja systemu HLS - Infofinder" />
  </Layout>
)

export default AboutUsPage
